import React, { createContext, useState } from "react"

// Initial context value until it gets updated
export const PageContext = createContext()

export default function PageContextProvider(props) {
  const { children } = props
  const [ctx, setCtx] = useState({})
  const [nextPage, setNextPage] = useState([])

  return (
    <PageContext.Provider value={{ ctx, setCtx, nextPage, setNextPage }}>
      {children}
    </PageContext.Provider>
  )
}
