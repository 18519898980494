// Packages
import React from 'react'

// Providers
import PageContextProvider from './src/context/PageContextProvider'
import ModalProvider from './src/context/ModalProvider'

// Context
import { PageContext }from './src/context/PageContextProvider'

export const wrapRootElement = ({ element }) => {
  return (
    <PageContextProvider>
      <ModalProvider>{element}</ModalProvider>
    </PageContextProvider>
  )
}

// exports.onInitialClientRender = () => {
//   const hamburger = document.querySelector("#hamburger")
//   const modal = document.querySelector("#modal")
//   const cross = document.querySelector("#modal")

//   hamburger.addEventListener("click", () => {
//     modal.classList.add("on")
//     modal.classList.remove("off")
//     hamburger.classList.add("open")
//     hamburger.classList.remove("closed")
//   })

//   cross.addEventListener("click", () => {
//     modal.classList.add("off")
//     modal.classList.remove("on")
//     hamburger.classList.add("closed")
//     hamburger.classList.remove("open")
//   })
// }

export const onRouteUpdate = ({ location }) => {
  const header = document.querySelector("#header")
  const heroSection = document.querySelector("#section-hero")
  const hamburger = document.querySelector("#hamburger")
  const modal = document.querySelector("#modal")
  const cross = document.querySelector("#modal")

  // Navbar anim
  const callback = entries => {
    if (entries[0].isIntersecting) {
      header.style.top = "-95px"
      hamburger.style.top = "-95px"
    } else {
      header.style.top = "0"
      hamburger.style.top = "16px"
    }
  }

  const observer = new IntersectionObserver(callback, { threshold: 0 })
  if (location.pathname === "/" || location.pathname === "/en/") {
    observer.observe(heroSection)
  }

  // Set signature screen size
  if (location.pathname === "/" || location.pathname === "/en/") {
    const resize = () => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }
    resize()
    // window.addEventListener("resize", resize)
  }

  // Mobile Menu
  if (hamburger && modal && cross) {
    hamburger.addEventListener("click", () => {
      modal.classList.toggle("on")
      modal.classList.toggle("off")
      hamburger.classList.toggle("open")
      hamburger.classList.toggle("closed")
    })
  }
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <PageContext.Consumer>
      {context => {
        const { setCtx } = context
        setCtx(props)
        return element
      }}
    </PageContext.Consumer>
  )
}
