const sharedButtonStyle = {
  py: [2, 2, 3],
  px: [4, 4, 5],
  border: 1,
  fontFamily: "body",
  borderColor: "primary",
  borderStyle: "solid",
  borderRadius: 0,
  color: "primary",
  bg: "transparent",
  cursor: "pointer",
}

const defaultPStyle = {
  fontSize: [2, 2, 3],
  fontFamily: "body",
  lineHeight: 1.46,
  fontWeight: 500,
  pb: 3,
}

const theme = {
  breakpoints: ["40rem", "48rem", "64rem", "84rem"],
  containerWidth: ["100%", "32rem", "44rem", "56rem", "80rem"],
  space: [0, 1, 2, 4, 8, 16, 24, 32, 48, 64, 96, 128, 192, 256, 512],
  fonts: {
    body: "Minion Pro",
    heading: "Sharp Sans",
  },
  colors: {
    primary: "#272F64",
    secondary: "#F1DFBE",
    blueFilter: "#cccccc",
    greyLight: "#DADADA",
    grey: "#C7C7C7",
    white: "#fff",
    background: "#fff",
  },
  shadows: {
    header: "4px 0 16px rgba(0, 0, 0, 0.09)",
  },
  fontSizes: [12, 14, 16, 18, 22, 26, 32, 40, 48, 64, 96],
  buttons: {
    primary: {
      ...sharedButtonStyle,
      fontSize: [2, 3],
      fontWeight: 500,
    },
    small: {
      ...sharedButtonStyle,
      fontSize: [2],
      fontWeight: 500,
      mr: 4,
      mb: 4,
      "&:hover": {
        backgroundColor: "primary",
        color: "white",
      },
    },
  },
  text: {
    h1: {
      fontSize: ["24px", "28px", "32px"],
      fontFamily: "heading",
      fontWeight: 500,
      lineHeight: 1.15,
      pt: 4,
      pb: 8,
    },
    h2: {
      fontSize: "24px",
      fontFamily: "body",
      fontWeight: 500,
      lineHeight: 1.15,
      pt: 6,
      pb: 2,
    },
    h3: {
      fontSize: ["24px", "28px", "32px"],
      fontFamily: "heading",
      fontWeight: 500,
      lineHeight: 1.15,
      pt: 6,
      pb: 4,
    },
    h4: {
      fontSize: ["18px", "20px", "24px"],
      fontFamily: "heading",
      fontWeight: 500,
      lineHeight: 1.3,
      pt: 2,
      pb: 2,
    },
    p: { ...defaultPStyle },
    li: { ...defaultPStyle },
    a: { ...defaultPStyle, fontWeight: 500 },
    bold: {
      ...defaultPStyle,
      fontWeight: 700,
    },
    small: {
      ...defaultPStyle,
      fontSize: [1, 2],
      fontFamily: "heading",
      fontWeight: 500,
      color: "secondary",
    },
    big: {
      ...defaultPStyle,
      fontSize: [3, 4],
      pb: [4, 6],
    },
    mobileLink: {
      fontSize: [5, 6],
      fontFamily: "heading",
      lineHeight: 1.46,
      fontWeight: 500,
      color: "primary",
      pb: 6,
    },
  },
}

export default theme
