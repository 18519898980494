// Packages
import React, { createContext, useState } from "react"

// Initial context value until it gets updated
export const ModalContext = createContext()

export default function ModalContextProvider(props) {
  const { children } = props
  const [open, setOpen] = useState(false)
  const [content, setContent] = useState(null)

  return (
    <ModalContext.Provider value={{ open, setOpen, content, setContent }}>
      {children}
    </ModalContext.Provider>
  )
}
