exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-expertise-index-js": () => import("./../../../src/templates/ExpertiseIndex.js" /* webpackChunkName: "component---src-templates-expertise-index-js" */),
  "component---src-templates-expertise-page-js": () => import("./../../../src/templates/ExpertisePage.js" /* webpackChunkName: "component---src-templates-expertise-page-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-ordinary-js": () => import("./../../../src/templates/Ordinary.js" /* webpackChunkName: "component---src-templates-ordinary-js" */),
  "component---src-templates-project-index-js": () => import("./../../../src/templates/ProjectIndex.js" /* webpackChunkName: "component---src-templates-project-index-js" */),
  "component---src-templates-project-page-js": () => import("./../../../src/templates/ProjectPage.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-team-index-js": () => import("./../../../src/templates/TeamIndex.js" /* webpackChunkName: "component---src-templates-team-index-js" */),
  "component---src-templates-team-page-js": () => import("./../../../src/templates/TeamPage.js" /* webpackChunkName: "component---src-templates-team-page-js" */)
}

